import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, Input } from "@angular/core";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { user } from '../user.model';
import { NgForm } from '@angular/forms';
import { SearchCountryField, TooltipLabel, CountryISO } from "ngx-intl-tel-input";
import { CommonServices } from 'src/app/services/commonservice';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { Time } from '@angular/common';
import * as _ from 'lodash';
@Component({
  selector: "app-create-user",
  templateUrl: "./create-user.component.html",
  styleUrls: ["./create-user.component.css"]
})

export class CreateUserComponent implements OnInit, OnDestroy {
  model; any;
  slectedDays: Array<any> = [];
  isChecked: boolean = true;
  startTime: any = {};
  endTime: any = {};
  accTime: any = [];
  startDate: any = [];
  endDate: any = [];
  allChecked: boolean = true;
  days: any = [];
  label: any = [];

  sundayStartTime: any = []
  mondayStartTime: any = []
  tuesdayStartTime: any = []
  wednesdayStartTime: any = []
  thursdayStartTime: any = []
  fridayStartTime: any = []
  satdayStartTime: any = []
  sundayEndTime: any = []
  mondayEndTime: any = []
  tuesdayEndTime: any = []
  wednesdayEndTime: any = []
  thursdayEndTime: any = []
  fridayEndTime: any = []
  satdayEndTime: any = []
  indexValue: any;
  sundayChecked: any = []
  mondayChecked: any = []
  tuesdayChecked: any = []
  wednesChecked: any = []
  thursdayChecked: any = []
  fridayChecked: any = []
  saturdayChecked: any = []

  sunday: any;
  monday: any;
  tuesday: any;
  wednesday: any;
  thursday: any;
  friday: any;
  saturday: any;
  sunAccess: boolean = false;
  monAccess: boolean = false;
  tueAccess: boolean = false;
  wedAccess: boolean = false;
  thuAccess: boolean = false;
  friAccess: boolean = false;
  satAccess: boolean = false;
  tzDeviceId: any;
  separateDialCode = true;
  sundayValues: any;
  mondayValues: any;
  tuesdayValues: any;
  wednesdayValues: any;
  thursdayValues: any;
  fridayValues: any;
  saturdayValues: any;
  @ViewChild('content',
    { static: true }
  ) content: ElementRef;
  @ViewChild('timeZone', { static: true }) timeZone: ElementRef;
  userModal: BsModalRef;
  modalRef: BsModalRef;
  subLocModal: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: false
  };
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() user: any = {};
  @Input() userDetails: any = {};
  phNumber: any;
  userForm: user;
  locations: any;
  subLocations: any = [];
  data: any = {
    addresses: []
  };
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];
  accessPoint: any = [];
  genericAccessCode: any = [];
  badgeId: any = [];
  selectedSubLocation: any = [];
  removedExistingAccess: any = [];
  removeExistingAccessDuration: any = [];
  todayDate: NgbDateStruct = undefined;
  addressSubLocations: any = [];
  isSafeTrustUser: boolean = false;
  phExists: boolean = false;
  removedSubLocations: any = [];
  listSubLocations: any = [];
  userAddress: any = [];
  credentialIdentifier: any = [];
  sDate: any;
  eDate: any;
  constructor(
    private modalService: NgbModal,
    private commonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService,
    private modalServices: BsModalService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter) {
    this.userForm = new user();
    this.data.addresses.push({});
    this.todayDate = calendar.getToday()
  }

  ngOnInit() {
    // this.modalService.open(this.content, { backdrop: 'static', size: 'xl', keyboard: false });
    if(!this.user.phoneNumber){
      this.modalService.open(this.content, { backdrop: 'static', size: 'xl', keyboard: false });
    }
    this.getLocations();
    if (this.user && this.user.phoneNumber) {
      this.getUserById(this.user.phoneNumber);
    }
  }
  ngOnDestroy() {
    this.modalService.dismissAll(this.content);
  }

  close() {
    this.modalService.dismissAll(this.content);
    this.onSubmit.emit(false);
  }

  getUserById(userId) {
    let custId = this.user.customerId;
    this.spinnerService.show();
    let token = localStorage.getItem("zerv");
    this.commonServices.getUserById(token, userId, custId).subscribe(
      res => {
        if (res.code == 200) {
          this.data = res;
          this.data["firstName"] = res.name.substr(
            0,
            res.name.indexOf(" ")
          );
          this.data["lastName"] = res.name.substr(
            res.name.indexOf(" ") + 1
          );
          this.data.phoneNumber = '+' + res.phoneNumber
          this.data.addresses = this.commonServices.copyObject(res.listGetUserAccess);
          this.userAddress = this.commonServices.copyObject(res.listGetUserAccess);
          if (this.data.addresses) {
            (this.data.addresses || []).map((add, i) => {
              add['genericAccessCode'] = add.accessCode;
              add['badgeId'] = add.accessCode;
              add['subLocation'] = add.accessPoint;
              // add['facilityId'] = add.facilityId;
              // add['credentialIdentifier'] = add.credentialIdentifier;
              this.addressSubLocations[i] = this.data.addresses[i].subLocation;
            });
          }
          // 
          for (let i = 0; i < res.listGetUserAccess.length; i++) {
            // this.getSubLocations(this.data.addresses[i].location, i, true);
          }
          if (this.addressSubLocations) {
            for (let i = 0; i < res.listGetUserAccess.length; i++) {
              this.getSubLocations(this.data.addresses[i].location, i, true);
            }
          }
          this.spinnerService.hide();
          this.modalService.open(this.content, { backdrop: 'static', size: 'xl', keyboard: false });
        } else {
          this.spinnerService.hide();
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
      }
    );
  }
  removeLocationAccess(item, index) {
    if (item && item.id) {
      this.removedExistingAccess.push(item.id);
      this.removeExistingAccessDuration.push(item.userAccessDurationId);
      // this.addRemovedSubLoactionAccess(item.subLocation);
    }
    this.addressSubLocations.splice(index, 1);
    this.data.addresses.splice(index, 1);
    this.subLocations.splice(index, 1);
  }

  resetForm(form: NgForm) {
    form.form.markAsPristine();
    form.form.markAsUntouched();
    form.form.reset();
    form.resetForm();
  }

  getLocations() {
    let token = localStorage.getItem("zerv");
    this.commonServices.getLocation(token).subscribe(
      res => {
        if (res.code == 200) {
          this.locations = res.listLocations;
        }
      },
      err => {
        // console.log(err);
      }
    );
  };

  getSubLocations(ev, i, onEdit?: boolean) {
    this.spinnerService.show();
    this.subLocations[i] = [];
    if (!onEdit) {
      this.clearAccessPoint(i);
    }
    let token = localStorage.getItem("zerv");
    this.commonServices.getSubLocation(token, ev).subscribe(
      res => {
        if (res.code == 200) {
          this.listSubLocations = res.listSublocations;
          this.subLocations[i] = res.listSublocations;
          if (this.subLocations && this.subLocations.length > 0) {
            if (res && res.listSublocations && res.listSublocations.length > 0) {
            }
            if (this.removedSubLocations && this.removedSubLocations.length > 0) {
              // this.addRemovedSubLoactionAccess(i,res.listSublocations);
            }
            // (this.subLocations[i] || []).forEach(selctSubLoc => {
            //   
            //   if (this.data.addresses && this.data.addresses[i].subLocation && (selctSubLoc.name === this.data.addresses[i].subLocation)) {
            //     this.selectedSubLocation[i] = selctSubLoc;
            //     // this.data.addresses[i].subLocation = selctSubLoc;

            //   }
            // })
            // } else {
            //   this.selectedSubLocation[i] = null;
          }
          this.spinnerService.hide();
        }
      },
      err => {
        this.spinnerService.hide();
        // console.log(err);
      }
    );
  };
  getAccess(subloc, index) {
    if (subloc) {
      let subLocation = {};
      (this.subLocations || []).forEach(userSubLocs => {
        (userSubLocs || []).forEach(userSubLoc => {
          if (userSubLoc.accessPoint === subloc) {
            this.addressSubLocations[index] = subloc;
            this.data.addresses[index].accessPoint = userSubLoc.accessPoint; //device id
            this.data.addresses[index].genericAccessCode = userSubLoc.genericAccessCode;
            this.data.addresses[index].badgeId = userSubLoc.genericAccessCode;
          }
        });
      });
    }
  }


  checkDuplication(subloc, index, address, subLocDup: TemplateRef<any>) {
    let changeSubLoc = this.commonServices.copyObject(this.addressSubLocations);
    let subLocModal: boolean;
    if (changeSubLoc.length > 0) {
      changeSubLoc.forEach((list, list_index) => {
        if (subloc == list) {
          subLocModal = true;
          return
        } else {
          this.getAccess(subloc, index);
        }
      })
    } else {
      this.getAccess(subloc, index)
    }

    if (subLocModal) {
      if (this.data.addresses[index].id) {
        let dataAddress = this.commonServices.copyObject(this.data.addresses[index]);
        let datarow = { id: dataAddress.id, userAccessDurationId: dataAddress.userAccessDurationId };
        this.data.addresses[index] = datarow;
      } else {
        this.data.addresses[index] = {};
      }
      this.subLocations[index] = [];
      this.addressSubLocations[index] = '';
      this.subLocModal = this.modalServices.show(subLocDup, { class: 'modal-sm', backdrop: false });
    }
    this.getInitalTimeAccess(address, index)
    this.startDate[this.indexValue] = '';
    this.endDate[this.indexValue] = ''
    this.sundayStartTime[index] = '';
    this.mondayStartTime[index] = '';
    this.tuesdayStartTime[index] = '';
    this.wednesdayStartTime[index] = '';
    this.thursdayStartTime[index] = '';
    this.fridayStartTime[index] = '';
    this.satdayStartTime[index] = '';
    this.sundayEndTime[index] = ''
    this.mondayEndTime[index] = ''
    this.tuesdayEndTime[index] = ''
    this.wednesdayEndTime[index] = ''
    this.thursdayEndTime[index] = ''
    this.fridayEndTime[index] = ''
    this.satdayEndTime[index] = ''
    if (this.accTime[index]) {
      this.accTime[index].sun_access_start_time = '00:00'
      this.accTime[index].mon_access_start_time = '00:00'
      this.accTime[index].tue_access_start_time = '00:00'
      this.accTime[index].wed_access_start_time = '00:00'
      this.accTime[index].thu_access_start_time = '00:00'
      this.accTime[index].fri_access_start_time = '00:00'
      this.accTime[index].sat_access_start_time = '00:00'

      this.accTime[index].sun_access_end_time = '23:59'
      this.accTime[index].mon_access_end_time = '23:59'
      this.accTime[index].tue_access_end_time = '23:59'
      this.accTime[index].wed_access_end_time = '23:59'
      this.accTime[index].thu_access_end_time = '23:59'
      this.accTime[index].fri_access_end_time = '23:59'
      this.accTime[index].sat_access_end_time = '23:59'
      //checkboxes
      this.accTime[index].sunAccess = true;
      this.accTime[index].monAccess = true;
      this.accTime[index].tueAccess = true;
      this.accTime[index].wedAccess = true;
      this.accTime[index].thuAccess = true;
      this.accTime[index].friAccess = true;
      this.accTime[index].satAccess = true;
    }
  }

  closeConfirm() {
    if (!this.subLocModal) {
      return;
    }
    this.subLocModal.hide();
    this.subLocModal = null;
  }

  removeAddedSublocations(indexx, added_sub_loc) {
    if (this.subLocations && this.subLocations.length > 1) {
      let added__sub_loc: any;
      (this.listSubLocations || []).forEach((sub_loc, sub_loc_index) => {
        if (added_sub_loc === sub_loc.accessPoint) {
          added__sub_loc = sub_loc;
        }
      });
      if (added__sub_loc) {
        let tmp_locs, row_loc = [];
        (this.subLocations || []).forEach((selected_sub_loc, selected_sub_loc_index) => {
          if (indexx !== selected_sub_loc_index) {
            let index = selected_sub_loc.map(sub_ => {
              return sub_.accessPoint;
            }).indexOf(added_sub_loc);
            if (index > -1) {
              selected_sub_loc.splice(index, 1);
            }
            // row_loc = (selected_sub_loc || []).forEach(sub_loc => {
            //   if(sub_loc.accessPoint !== added_sub_loc){
            //     tmp_locs.push(sub_loc);
            //   }
            //   return tmp_locs;
            // });
            // return row_loc;
          }
        });
      }
    }
  }
  checkAddSelectedSubLoacations(index, listSublocations, addressSubLocations) {
    (addressSubLocations || []).forEach((add_sub_loc, add_sub_loc_index) => {
      (listSublocations || []).forEach((sub_loc, sub_loc_index) => {
        if (add_sub_loc === sub_loc.accessPoint && index === add_sub_loc_index) {
          this.subLocations[index].push(sub_loc);
        }
      });
    });
  }
  addRemovedSubLoactionAccess(remove_sub_loc) {
    let deleted_sub_loc: any;
    (this.listSubLocations || []).forEach((sub_loc, sub_loc_index) => {
      if (remove_sub_loc === sub_loc.accessPoint) {
        deleted_sub_loc = sub_loc;
        // this.subLocations[index].push(remove_sub_loc);
      }
    });
    if (deleted_sub_loc) {
      (this.subLocations || []).map(selected_sub_loc => {
        selected_sub_loc.push(deleted_sub_loc);
      });
    }
  }
  getInitalTimeAccess(address, i) {
    // var index = i
    this.indexValue = i
    this.tzDeviceId = address.accessPoint;
    if (this.data.id && this.data.id != "0" && address.userAccessDurationId &&
      this.tzDeviceId == this.data.listGetUserAccess[i].accessPoint) {
      for (var list of this.data.listGetUserAccess) {

        if (this.tzDeviceId == list.accessPoint) {
          if (address.accessStartDate) {
            var strtDate = address.accessStartDate;
            var stDt = strtDate.slice(0, 10);
            this.startDate = { "year": parseInt(stDt.split("-")[0]), "month": parseInt(stDt.split("-")[1]), "day": parseInt(stDt.split("-")[2]) }
            this.startDate[this.indexValue] = this.startDate
          } else {
            this.startDate[this.indexValue] = ''
          }
          if (address.accessEndDate) {
            this.endDate = address.accessEndDate;
            var edDt = this.endDate.slice(0, 10);
            this.endDate = { "year": parseInt(edDt.split("-")[0]), "month": parseInt(edDt.split("-")[1]), "day": parseInt(edDt.split("-")[2]) }
            this.endDate[this.indexValue] = this.endDate;
          } else {
            this.endDate[this.indexValue] = '';
          }
          if (address.sunAccess && address.monAccess && address.tueAccess && address.wedAccess && address.thuAccess && address.friAccess && address.satAccess) {
            this.allChecked = true;
          } else {
            this.allChecked = false;
          }
          this.days = [
            { "label": 'Sunday', "startTime": address.sun_access_start_time, "endTime": address.sun_access_end_time, 'isChecked': address.sunAccess },
            { "label": 'Monday', "startTime": address.mon_access_start_time, "endTime": address.mon_access_end_time, 'isChecked': address.monAccess },
            { "label": 'Tuesday', "startTime": address.tue_access_start_time, "endTime": address.tue_access_end_time, 'isChecked': address.tueAccess },
            { "label": 'Wednesday', "startTime": address.wed_access_start_time, "endTime": address.wed_access_end_time, 'isChecked': address.wedAccess },
            { "label": 'Thursday', "startTime": address.thu_access_start_time, "endTime": address.thu_access_end_time, 'isChecked': address.thuAccess },
            { "label": 'Friday', "startTime": address.fri_access_start_time, "endTime": address.fri_access_end_time, 'isChecked': address.friAccess },
            { "label": 'Saturday', "startTime": address.sat_access_start_time, "endTime": address.sat_access_end_time, 'isChecked': address.satAccess },
          ];
          this.onSubmitTimeZone();
        }
      }
    } else {
      if (this.startDate[this.indexValue] == '' || this.startDate[this.indexValue] == undefined) {
        this.startDate[this.indexValue] = this.todayDate
      }
      if (this.endDate[this.indexValue] == '' || this.endDate[this.indexValue] == undefined) {
        this.endDate[this.indexValue] = { 'year': this.todayDate.year + 10, 'month': this.todayDate.month, 'day': this.todayDate.day }
      }
      if (this.sundayStartTime[this.indexValue] == '' || this.sundayStartTime[this.indexValue] == undefined) {
        this.sundayStartTime[this.indexValue] = '00:00'
      }
      if (this.mondayStartTime[this.indexValue] == '' || this.mondayStartTime[this.indexValue] == undefined) {
        this.mondayStartTime[this.indexValue] = '00:00'
      }
      if (this.tuesdayStartTime[this.indexValue] == '' || this.tuesdayStartTime[this.indexValue] == undefined) {
        this.tuesdayStartTime[this.indexValue] = '00:00'
      }
      if (this.wednesdayStartTime[this.indexValue] == '' || this.wednesdayStartTime[this.indexValue] == undefined) {
        this.wednesdayStartTime[this.indexValue] = '00:00'
      }
      if (this.thursdayStartTime[this.indexValue] == '' || this.thursdayStartTime[this.indexValue] == undefined) {
        this.thursdayStartTime[this.indexValue] = '00:00'
      }
      if (this.fridayStartTime[this.indexValue] == '' || this.fridayStartTime[this.indexValue] == undefined) {
        this.fridayStartTime[this.indexValue] = '00:00'
      }
      if (this.satdayStartTime[this.indexValue] == '' || this.satdayStartTime[this.indexValue] == undefined) {
        this.satdayStartTime[this.indexValue] = '00:00'
      }
      if (this.sundayEndTime[this.indexValue] == '' || this.sundayEndTime[this.indexValue] == undefined) {
        this.sundayEndTime[this.indexValue] = '23:59'
      }
      if (this.mondayEndTime[this.indexValue] == '' || this.mondayEndTime[this.indexValue] == undefined) {
        this.mondayEndTime[this.indexValue] = '23:59'
      }
      if (this.tuesdayEndTime[this.indexValue] == '' || this.tuesdayEndTime[this.indexValue] == undefined) {
        this.tuesdayEndTime[this.indexValue] = '23:59'
      }
      if (this.wednesdayEndTime[this.indexValue] == '' || this.wednesdayEndTime[this.indexValue] == undefined) {
        this.wednesdayEndTime[this.indexValue] = '23:59'
      }
      if (this.thursdayEndTime[this.indexValue] == '' || this.thursdayEndTime[this.indexValue] == undefined) {
        this.thursdayEndTime[this.indexValue] = '23:59'
      }
      if (this.fridayEndTime[this.indexValue] == '' || this.fridayEndTime[this.indexValue] == undefined) {
        this.fridayEndTime[this.indexValue] = '23:59'
      }
      if (this.satdayEndTime[this.indexValue] == '' || this.satdayEndTime[this.indexValue] == undefined) {
        this.satdayEndTime[this.indexValue] = '23:59'
      }
      if (this.accTime[this.indexValue] && this.accTime[this.indexValue].deviceMACId) {
        this.sundayChecked[this.indexValue] = this.accTime[this.indexValue].sunAccess;
        this.mondayChecked[this.indexValue] = this.accTime[this.indexValue].monAccess;
        this.tuesdayChecked[this.indexValue] = this.accTime[this.indexValue].tueAccess;
        this.wednesChecked[this.indexValue] = this.accTime[this.indexValue].wedAccess;
        this.thursdayChecked[this.indexValue] = this.accTime[this.indexValue].thuAccess;
        this.fridayChecked[this.indexValue] = this.accTime[this.indexValue].friAccess;
        this.saturdayChecked[this.indexValue] = this.accTime[this.indexValue].satAccess;
      } else {
        this.sundayChecked[this.indexValue] = true;
        this.mondayChecked[this.indexValue] = true;
        this.tuesdayChecked[this.indexValue] = true;
        this.wednesChecked[this.indexValue] = true;
        this.thursdayChecked[this.indexValue] = true;
        this.fridayChecked[this.indexValue] = true;
        this.saturdayChecked[this.indexValue] = true;
      }
      this.days = [
        { "label": 'Sunday', "startTime": this.sundayStartTime[this.indexValue], "endTime": this.sundayEndTime[this.indexValue], 'isChecked': this.sundayChecked[this.indexValue] },
        { "label": 'Monday', "startTime": this.mondayStartTime[this.indexValue], "endTime": this.mondayEndTime[this.indexValue], 'isChecked': this.mondayChecked[this.indexValue] },
        { "label": 'Tuesday', "startTime": this.tuesdayStartTime[this.indexValue], "endTime": this.tuesdayEndTime[this.indexValue], 'isChecked': this.tuesdayChecked[this.indexValue] },
        { "label": 'Wednesday', "startTime": this.wednesdayStartTime[this.indexValue], "endTime": this.wednesdayEndTime[this.indexValue], 'isChecked': this.wednesChecked[this.indexValue] },
        { "label": 'Thursday', "startTime": this.thursdayStartTime[this.indexValue], "endTime": this.thursdayEndTime[this.indexValue], 'isChecked': this.thursdayChecked[this.indexValue] },
        { "label": 'Friday', "startTime": this.fridayStartTime[this.indexValue], "endTime": this.fridayEndTime[this.indexValue], 'isChecked': this.fridayChecked[this.indexValue] },
        { "label": 'Saturday', "startTime": this.satdayStartTime[this.indexValue], "endTime": this.satdayEndTime[this.indexValue], 'isChecked': this.saturdayChecked[this.indexValue] },
      ];
      if (this.sundayChecked[this.indexValue] && this.mondayChecked[this.indexValue] && this.tuesdayChecked[this.indexValue] && this.wednesChecked[this.indexValue] && this.thursdayChecked[this.indexValue] && this.fridayChecked[this.indexValue] && this.saturdayChecked[this.indexValue]) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
      this.onSubmitTimeZone();
    }
  }

  clearAccessPoint(index) {
    this.data.addresses[index].accessPoint = null; //device id
    this.data.addresses[index].genericAccessCode = null;
    this.data.addresses[index].badgeId = null;
    this.data.addresses[index].facilityId = null;
    this.data.addresses[index].credentialIdentifier = null;
  }

  addNewAddress() {
    this.userForm = new user();
    let address = this.commonServices.copyObject(this.data.addresses);
    address[(address.length)] = {};
    this.data.addresses = address;
    this.selectedSubLocation = [];
  }

  checkSubLocationExists(index, resSubLocations) {
    let subLocations = this.commonServices.copyObject(resSubLocations);
    let userAddLocations = this.commonServices.copyObject(this.addressSubLocations);

    let userSubLocations: any = [];
    if (userAddLocations && userAddLocations.length > 0) {
      let results;
      (userAddLocations || []).filter(userSubLoc => {
        results = [];
        (subLocations || []).filter((subLoc, sub_loc_i) => {
          if (subLoc.accessPoint != userSubLoc) {
            results.push(subLoc);
          }
        });
        subLocations = results;
      });
      return subLocations;
    } else {
      return resSubLocations;
    }
  }
  openTzModal(address, timeZone: TemplateRef<any>, i) {
    this.getInitalTimeAccess(address, i);
    this.modalRef = this.modalServices.show(timeZone, { class: 'modal-lg', backdrop: false, keyboard: false });
  }

  closeTzModal(): void {
    this.modalRef.hide();
  }

  decline(): void {
    this.days = [];
    this.modalRef.hide();
    // this.modalService.dismissAll(this.timeZone);
    // this.onSubmit.emit(false);
  }
  changeWeek(event) {

    this.days.forEach(obj => {
      if (obj.label == event.target.name) {

        obj["isChecked"] = event.target.checked;
      }
    });
    if (event.target.name == 'Sunday') {
      if (this.days[0].isChecked) {
        this.sunAccess = this.days[0].isChecked;
        this.sundayChecked[this.indexValue] = this.days[0].isChecked
        this.sunday = [{ "sun_access_start_time": this.days[0].startTime, "sun_access_end_time": this.days[0].endTime }];
        this.sundayStartTime[this.indexValue] = this.days[0].startTime, this.sundayEndTime[this.indexValue] = this.days[0].endTime
      }
      else {
        this.allChecked = false
        this.sundayChecked[this.indexValue] = this.days[0].isChecked
        this.sundayStartTime[this.indexValue] = this.days[0].startTime, this.sundayEndTime[this.indexValue] = this.days[0].endTime
      }
    }

    if (event.target.name == 'Monday') {
      if (this.days[1].isChecked) {
        this.monAccess = this.days[1].isChecked;
        this.mondayChecked[this.indexValue] = this.days[1].isChecked
        this.monday = [{ "mon_access_start_time": this.days[1].startTime, "mon_access_end_time": this.days[1].endTime }];
        this.mondayStartTime[this.indexValue] = this.days[1].startTime, this.mondayEndTime[this.indexValue] = this.days[1].endTime
      }
      else {
        this.allChecked = false
        this.mondayChecked[this.indexValue] = this.days[1].isChecked
        this.mondayStartTime[this.indexValue] = this.days[1].startTime, this.mondayEndTime[this.indexValue] = this.days[1].endTime
      }
    }

    if (event.target.name == 'Tuesday') {
      if (this.days[2].isChecked) {
        this.tueAccess = this.days[2].isChecked;
        this.tuesdayChecked[this.indexValue] = this.days[2].isChecked
        this.tuesday = [{ "tue_access_start_time": this.days[2].startTime, "tue_access_end_time": this.days[2].endTime }];
        this.tuesdayStartTime[this.indexValue] = this.days[2].startTime, this.tuesdayEndTime[this.indexValue] = this.days[2].endTime
      }
      else {
        this.allChecked = false
        this.tuesdayChecked[this.indexValue] = this.days[2].isChecked
        this.tuesdayStartTime[this.indexValue] = this.days[2].startTime, this.tuesdayEndTime[this.indexValue] = this.days[2].endTime
      }
    }

    if (event.target.name == 'Wednesday') {
      if (this.days[3].isChecked) {
        this.wedAccess = this.days[3].isChecked;
        this.wednesChecked[this.indexValue] = this.days[3].isChecked
        this.wednesday = [{ "wed_access_start_time": this.days[3].startTime, "wed_access_end_time": this.days[3].endTime }];
        this.wednesdayStartTime[this.indexValue] = this.days[3].startTime, this.wednesdayEndTime[this.indexValue] = this.days[3].endTime
      }
      else {
        this.allChecked = false
        this.wednesChecked[this.indexValue] = this.days[3].isChecked
        this.wednesdayStartTime[this.indexValue] = this.days[3].startTime, this.wednesdayEndTime[this.indexValue] = this.days[3].endTime
      }
    }

    if (event.target.name == 'Thursday') {
      if (this.days[4].isChecked) {
        this.thuAccess = this.days[4].isChecked;
        this.thursdayChecked[this.indexValue] = this.days[4].isChecked
        this.thursday = [{ "thu_access_start_time": this.days[4].startTime, "thu_access_end_time": this.days[4].endTime }];
        this.thursdayStartTime[this.indexValue] = this.days[4].startTime, this.thursdayEndTime[this.indexValue] = this.days[4].endTime
      }
      else {
        this.allChecked = false
        this.thursdayChecked[this.indexValue] = this.days[4].isChecked
        this.thursdayStartTime[this.indexValue] = this.days[4].startTime, this.thursdayEndTime[this.indexValue] = this.days[4].endTime
      }
    }

    if (event.target.name == 'Friday') {
      if (this.days[5].isChecked) {
        this.friAccess = this.days[5].isChecked;
        this.fridayChecked[this.indexValue] = this.days[5].isChecked
        this.friday = [{ "fri_access_start_time": this.days[5].startTime, "fri_access_end_time": this.days[5].endTime }];
        this.fridayStartTime[this.indexValue] = this.days[5].startTime, this.fridayEndTime[this.indexValue] = this.days[5].endTime
      }
      else {
        this.allChecked = false
        this.fridayChecked[this.indexValue] = this.days[5].isChecked
        this.fridayStartTime[this.indexValue] = this.days[5].startTime, this.fridayEndTime[this.indexValue] = this.days[5].endTime
      }
    }

    if (event.target.name == 'Saturday') {
      if (this.days[6].isChecked) {
        this.satAccess = this.days[6].isChecked;
        this.saturdayChecked[this.indexValue] = this.days[6].isChecked
        this.saturday = [{ "sat_access_start_time": this.days[6].startTime, "sat_access_end_time": this.days[6].endTime }];
        this.satdayStartTime[this.indexValue] = this.days[6].startTime, this.satdayEndTime[this.indexValue] = this.days[6].endTime
      }
      else {
        this.allChecked = false
        this.saturdayChecked[this.indexValue] = this.days[6].isChecked
        this.satdayStartTime[this.indexValue] = this.days[6].startTime, this.satdayEndTime[this.indexValue] = this.days[6].endTime
      }
    }
    if (this.days[0].isChecked == true && this.days[1].isChecked == true && this.days[2].isChecked == true && this.days[3].isChecked == true && this.days[4].isChecked == true && this.days[5].isChecked == true && this.days[6].isChecked == true) {
      this.allChecked = true;
    } else {
      this.allChecked = false;
    }
    this.sundayValues = this.sunday
    this.mondayValues = this.monday
    this.tuesdayValues = this.tuesday
    this.wednesdayValues = this.wednesday
    this.thursdayValues = this.thursday
    this.fridayValues = this.friday
    this.saturdayValues = this.saturday
  }
  dateCheck() {
    //StartDate
    if (this.startDate[this.indexValue]) {
      var stMonth = this.startDate[this.indexValue].month
      function getStartMonthFormat(stMonth) {
        return (stMonth < 10) ? '0' + stMonth : '' + stMonth;
      }
      var stDay = this.startDate[this.indexValue].day
      function getStartDateFormat(stDay) {
        return (stDay < 10) ? '0' + stDay : '' + stDay;
      }
      var sDateMonth = getStartMonthFormat(stMonth);
      var sDateDay = getStartDateFormat(stDay);
      this.sDate = this.startDate[this.indexValue].year + "-" + sDateMonth + "-" + sDateDay;
    } else {
      this.sDate = '';
    }
    //EndDate
    if (this.endDate[this.indexValue]) {
      var eMonth = this.endDate[this.indexValue].month
      function getEndMonthFormat(eMonth) {
        return (eMonth < 10) ? '0' + eMonth : '' + eMonth;
      }
      var eDay = this.endDate[this.indexValue].day
      function getEndDateFormat(eDay) {
        return (eDay < 10) ? '0' + eDay : '' + eDay;
      }
      var eDateMonth = getEndMonthFormat(eMonth);
      var eDateDay = getEndDateFormat(eDay);
      this.eDate = this.endDate[this.indexValue].year + "-" + eDateMonth + "-" + eDateDay;
    } else {
      this.eDate = '';
    }
    if (this.sDate > this.eDate) {
      alert("Please ensure that the End Date is greater than or equal to the Start Date.");
      this.endDate[this.indexValue] = "";
      this.eDate = "";
      return false;
    }
  }
  timeCheck() {
    let stime = this.days[this.indexValue].startTime;
    let etime = this.days[this.indexValue].endTime;
    if (stime > etime || stime == etime) {
      alert("Please ensure that the End Time is greater than Start Time.");
      this.days[this.indexValue].endTime = "";
      etime = "";
      return false;
    }
  }
  onSubmitTimeZone() {
    this.dateCheck();
    let userData = this.commonServices.copyObject(this.data);
    var phoneNumber = userData.phoneNumber
    let obj: any;
    this.sunAccess = this.days[0].isChecked;
    this.monAccess = this.days[1].isChecked;
    this.tueAccess = this.days[2].isChecked;
    this.wedAccess = this.days[3].isChecked;
    this.thuAccess = this.days[4].isChecked;
    this.friAccess = this.days[5].isChecked;
    this.satAccess = this.days[6].isChecked;
    var sunday: any = [];
    var monday: any = [];
    var tuesday: any = [];
    var wednesday: any = [];
    var thursday: any = [];
    var friday: any = [];
    var saturday: any = [];
    if (this.sunAccess) {
      // sunday = this.sundayValues[0];
      sunday = [{ "sun_access_start_time": this.days[0].startTime, "sun_access_end_time": this.days[0].endTime }];
      this.sundayStartTime[this.indexValue] = this.days[0].startTime, this.sundayEndTime[this.indexValue] = this.days[0].endTime
    }
    else {
      sunday = '';
    }
    if (this.monAccess) {
      // monday = this.mondayValues[0];
      monday = [{ "mon_access_start_time": this.days[1].startTime, "mon_access_end_time": this.days[1].endTime }];
      this.mondayStartTime[this.indexValue] = this.days[1].startTime, this.mondayEndTime[this.indexValue] = this.days[1].endTime
    }
    else {
      monday = "";
    }
    if (this.tueAccess) {
      // tuesday = this.tuesdayValues[0];
      tuesday = [{ "tue_access_start_time": this.days[2].startTime, "tue_access_end_time": this.days[2].endTime }];
      this.tuesdayStartTime[this.indexValue] = this.days[2].startTime, this.tuesdayEndTime[this.indexValue] = this.days[2].endTime
    }
    else {
      tuesday = "";
    }
    if (this.wedAccess) {
      // wednesday = this.wednesdayValues[0];
      wednesday = [{ "wed_access_start_time": this.days[3].startTime, "wed_access_end_time": this.days[3].endTime }];
      this.wednesdayStartTime[this.indexValue] = this.days[3].startTime, this.wednesdayEndTime[this.indexValue] = this.days[3].endTime
    }
    else {
      wednesday = "";
    }
    if (this.thuAccess) {
      // thursday = this.thursdayValues[0];
      thursday = [{ "thu_access_start_time": this.days[4].startTime, "thu_access_end_time": this.days[4].endTime }];
      this.thursdayStartTime[this.indexValue] = this.days[4].startTime, this.thursdayEndTime[this.indexValue] = this.days[4].endTime
    }
    else {
      thursday = "";
    }
    if (this.friAccess) {

      // friday = this.fridayValues[0];
      friday = [{ "fri_access_start_time": this.days[5].startTime, "fri_access_end_time": this.days[5].endTime }];
      this.fridayStartTime[this.indexValue] = this.days[5].startTime, this.fridayEndTime[this.indexValue] = this.days[5].endTime
    }
    else {
      friday = "";
    }
    if (this.satAccess) {
      ;
      // saturday = this.saturdayValues[0];
      saturday = [{ "sat_access_start_time": this.days[6].startTime, "sat_access_end_time": this.days[6].endTime }];
      this.satdayStartTime[this.indexValue] = this.days[6].startTime, this.satdayEndTime[this.indexValue] = this.days[6].endTime
    }
    else {
      saturday = "";
    }
    var sun_st: any;
    var sun_et: any;
    var monday_st: any;
    var monday_et: any;
    var tue_st: any;
    var tue_et: any;
    var wed_st: any;
    var wed_et: any;
    var thu_st: any;
    var thu_et: any;
    var fri_st: any;
    var fri_et: any;
    var sat_st: any;
    var sat_et: any;
    if (sunday != "") {
      sun_st = sunday[0].sun_access_start_time,
        sun_et = sunday[0].sun_access_end_time
    } else {
      sun_st = "",
        sun_et = ""
    }
    if (monday != "") {
      monday_st = monday[0].mon_access_start_time,
        monday_et = monday[0].mon_access_end_time
    } else {
      monday_st = "",
        monday_et = ""
    }
    if (tuesday != "") {
      tue_st = tuesday[0].tue_access_start_time,
        tue_et = tuesday[0].tue_access_end_time
    } else {
      tue_st = "",
        tue_et = ""
    }
    if (wednesday != "") {
      wed_st = wednesday[0].wed_access_start_time,
        wed_et = wednesday[0].wed_access_end_time
    } else {
      wed_st = "",
        wed_et = ""
    }
    if (thursday != "") {
      thu_st = thursday[0].thu_access_start_time,
        thu_et = thursday[0].thu_access_end_time
    } else {
      thu_st = "",
        thu_et = ""
    }
    if (friday != "") {
      fri_st = friday[0].fri_access_start_time,
        fri_et = friday[0].fri_access_end_time
    } else {
      fri_st = "",
        fri_et = ""
    }
    if (saturday != "") {
      sat_st = saturday[0].sat_access_start_time,
        sat_et = saturday[0].sat_access_end_time
    } else {
      sat_st = "",
        sat_et = ""
    }
    let accData = this.data.addresses
    this.accTime = accData;
    accData.forEach((id, i) => {
      if (id.accessStartDate) {
        if (id.accessStartDate.length > 10) {
          var startDt = id.accessStartDate.slice(0, 10);
          id.accessStartDate = startDt;
        }
      }
      // else {
      //   id.accessStartDate = "";
      // }
      if (id.accessEndDate) {
        if (id.accessEndDate.length > 10) {
          var endDt = id.accessEndDate.slice(0, 10);
          id.accessEndDate = endDt;
        }
      }
      // else {
      //   id.accessEndDate = "";
      // }
      if (id.accessPoint == this.tzDeviceId) {
        if (this.accTime == '') {
          id.deviceMACId = this.tzDeviceId,
            id.phoneNumber = phoneNumber,
            id.accessStartDate = this.sDate,
            id.accessEndDate = this.eDate,
            id.monAccess = this.monAccess,
            id.tueAccess = this.tueAccess,
            id.wedAccess = this.wedAccess,
            id.thuAccess = this.thuAccess,
            id.friAccess = this.friAccess,
            id.satAccess = this.satAccess,
            id.sunAccess = this.sunAccess,
            id.mon_access_start_time = monday_st,
            id.tue_access_start_time = tue_st,
            id.wed_access_start_time = wed_st,
            id.thu_access_start_time = thu_st,
            id.fri_access_start_time = fri_st,
            id.sat_access_start_time = sat_st,
            id.sun_access_start_time = sun_st,
            id.mon_access_end_time = monday_et,
            id.tue_access_end_time = tue_et,
            id.wed_access_end_time = wed_et,
            id.thu_access_end_time = thu_et,
            id.fri_access_end_time = fri_et,
            id.sat_access_end_time = sat_et,
            id.sun_access_end_time = sun_et
          this.accTime.push(id)
          // console.log(this.accTime)
        }
        else {
          for (var list of accData) {
            if (list.accessPoint == this.tzDeviceId) {
              list.deviceMACId = this.tzDeviceId,
                list.phoneNumber = phoneNumber
              list.accessStartDate = this.sDate,
                list.accessEndDate = this.eDate
              list.monAccess = this.monAccess,
                list.tueAccess = this.tueAccess,
                list.wedAccess = this.wedAccess,
                list.thuAccess = this.thuAccess,
                list.friAccess = this.friAccess,
                list.satAccess = this.satAccess,
                list.sunAccess = this.sunAccess,
                list.mon_access_start_time = monday_st,
                list.tue_access_start_time = tue_st,
                list.wed_access_start_time = wed_st,
                list.thu_access_start_time = thu_st,
                list.fri_access_start_time = fri_st,
                list.sat_access_start_time = sat_st,
                list.sun_access_start_time = sun_st,
                list.mon_access_end_time = monday_et,
                list.tue_access_end_time = tue_et,
                list.wed_access_end_time = wed_et,
                list.thu_access_end_time = thu_et,
                list.fri_access_end_time = fri_et,
                list.sat_access_end_time = sat_et,
                list.sun_access_end_time = sun_et
              this.accTime = accData
              // console.log(this.accTime)
            }
          }
        }
      }
    })
  }
  // SafeTrustUser(event) {
  //   this.isSafeTrustUser = event.target.checked
  // }
  onUserSubmit(form: NgForm) {
    // debugger;
    if (form.invalid) {
      return
    }
    this.spinnerService.show();    
    let userData = this.commonServices.copyObject(this.data);
    let userAccess = this.commonServices.copyObject(this.data.addresses);
    let userAccessList = []
    userAccess.forEach(acc => {
      let accobj = {};
      let accessTime = [];
      accessTime = this.accTime
      // console.log(accessTime)
      if (accessTime.length == 0) {
        accessTime = userAccess
      }
      for (var list of accessTime) {
        if (acc.accessPoint == list.accessPoint) {
          accobj['id'] = list.id ? list.id : "0";
          accobj['accessCode'] = acc.badgeId ? acc.badgeId: null;
          accobj['accessPoint'] = acc.accessPoint;
          accobj['facilityId'] = acc.facilityId ? acc.facilityId: null;
          accobj['credentialIdentifier'] = acc.credentialIdentifier ? acc.credentialIdentifier: null;
          // if (accobj && accobj['id'] && accobj['id'] != "0") {
          //   accobj['active'] = acc.access;
          // }
          // accobj['id'] = list.id;
          if (accobj['id'] && accobj['id'] != "0") {
            accobj['active'] = acc.access;
            accobj["userAccessDurationId"] = list.userAccessDurationId;
          } else {
            accobj["userAccessDurationId"] = "0";
          }
          accobj['mon_access_start_time'] = list.mon_access_start_time ? list.mon_access_start_time: null
          accobj['tue_access_start_time'] = list.tue_access_start_time ? list.tue_access_start_time: null
          accobj['wed_access_start_time'] = list.wed_access_start_time ? list.wed_access_start_time: null
          accobj['thu_access_start_time'] = list.thu_access_start_time ? list.thu_access_start_time: null
          accobj['fri_access_start_time'] = list.fri_access_start_time ? list.fri_access_start_time: null
          accobj['sat_access_start_time'] = list.sat_access_start_time ? list.sat_access_start_time: null
          accobj['sun_access_start_time'] = list.sun_access_start_time ? list.sun_access_start_time: null
          accobj['mon_access_end_time'] = list.mon_access_end_time ? list.mon_access_end_time: null
          accobj['tue_access_end_time'] = list.tue_access_end_time ? list.tue_access_end_time: null
          accobj['wed_access_end_time'] = list.wed_access_end_time ? list.wed_access_end_time: null
          accobj['thu_access_end_time'] = list.thu_access_end_time ? list.thu_access_end_time: null
          accobj['fri_access_end_time'] = list.fri_access_end_time ? list.fri_access_end_time: null
          accobj['sat_access_end_time'] = list.sat_access_end_time ? list.sat_access_end_time: null
          accobj['sun_access_end_time'] = list.sun_access_end_time ? list.sun_access_end_time: null
          // accobj['phoneNumber'] = list.phoneNumber,
          // accobj['deviceMACId'] = list.deviceMACId,
          accobj['accessStartDate'] = list.accessStartDate,
            accobj['accessEndDate'] = list.accessEndDate,
            accobj['monAccess'] = list.monAccess,
            accobj['tueAccess'] = list.tueAccess,
            accobj['wedAccess'] = list.wedAccess,
            accobj['thuAccess'] = list.thuAccess,
            accobj['friAccess'] = list.friAccess,
            accobj['satAccess'] = list.satAccess,
            accobj['sunAccess'] = list.sunAccess,
            userAccessList.push(accobj);
        }
      }
    });
    // console.log(userAccessList);
    let data = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      image: null,
      // isSafeTrustUser: this.isSafeTrustUser,
      listAddUserAccess: userAccessList,
    };
    if (userData.id) {
      data['phoneNumber'] = userData.phoneNumber
    } else {
      data['phoneNumber'] = (userData.phoneNumber.internationalNumber).replace(/[- )(]/g, "")
    }
    let token = localStorage.getItem("zerv");
    let serviceUrl;
    if (userData && userData.id) {
      data['id'] = userData.id;
      data['removedExistingAccess'] = this.removedExistingAccess;
      data['removeExistingAccessDuration'] = this.removeExistingAccessDuration;
      serviceUrl = this.commonServices.updateUser(token, data);
    }
    else {
      serviceUrl = this.commonServices.postUser(token, data);
    }
    serviceUrl.subscribe(
      res => {
        if (res.code == 200 && res.status == "success") {
          this.onSubmit.emit(res);
          this.spinnerService.hide();
          this.commonServices.showSuccess("User Data Updated Successfully", "User Updated")
        }
        this.resetForm(form);
      },
      err => {
        console.log(err)
        // this.onSubmitLoading = false; 
        this.spinnerService.hide();
        this.commonServices.showError("User Data was not Updated Successfully", "Try Again")
        // this.phExists = true;
      }
    );
    // console.log(data)
  }
  allSelected(event) {

    const checked = event.target.checked;
    // console.log(this.days)
    var selectall: any = []
    var days = this.days
    days.forEach(obj => obj['isChecked'] = checked);
    for (var list of this.days) {
      if (list.isChecked == true) {
        selectall.push(list)
      }
    }

    if (selectall != "") {
      this.days = selectall
    }
    else {
      this.days = days
    }
  }
  phNumberNotInList(ev) {
    this.phNumber = null;
    let phoneNumber = ev.dialCode + "" + ev.number;
    phoneNumber = phoneNumber.replace(/[- )(]/g, "");
    phoneNumber = phoneNumber.replace("+", "");
    return (this.userDetails || []).filter(item => {
      if (phoneNumber == item.phoneNumber) {
        this.phNumber = item.phoneNumber;
      }
    });
  }
}
