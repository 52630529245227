import { Injectable, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import * as moment from 'moment';
import { CommonService } from '../services/common.service';
@Injectable()
export class AuthService {
  public onLogOut: EventEmitter<any>;
  public apiToken;
  public authentication;
  onInvalidApiToken: EventEmitter<any>;
  crole: any;
  zrole: any;
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(private router: Router, private commonService: CommonService) {

    this.onLogOut = new EventEmitter();
  }

  getToken() {
    return localStorage.getItem("Role")
  }
  isLoggedIn() {
    return this.getToken() !== null;
  }

  // logout() {
  //   this.loggedIn.next(false);
  //   this.router.navigate(["/login"]);
  // }



  init(): void {
    this.apiToken = null;
    this.authentication = null;
    const data = this.getLocalStorage('zerv', true);
    if (data) {
      let currentuser = JSON.parse(atob(data.idToken.split(".")[1]));
      this.authentication = currentuser;
      this.authentication['userRole'] = this.authentication['custom:role'];
      this.apiToken = data['idToken'];
      this.commonService.setCookie("token", this.apiToken, this.getValidCookieTime(this.authentication));
      if (this.authentication['custom:role'] == 'Client-Admin') {
        this.crole = 'client';
        this.zrole = null;
      }
      if (this.authentication['custom:role'] == 'Zerv-Admin') {
        this.zrole = 'zerv';
        this.crole = null;
      }
    }

  }

  removeLocalStorage(key): void {
    localStorage.removeItem(key);
  }

  getLocalStorage(key, decoded): any {
    let value = localStorage.getItem(key);
    try {
      value = (value) ? JSON.parse((decoded) ? atob(value) : value) : null;
      return value;
    } catch (e) {
      return null;
    }
  }

  hasLoginSession(): boolean {
    const data = this.getLocalStorage('zerv', true);
    return (data) ? true : false;
  }

  login(data): void {
    this.setLocalStorage('zerv', data, true);
    this.init();
  }
  invalidApiToken(): void {
    this.onInvalidApiToken.emit(true);
  }

  setLocalStorage(key, value, encoded): void {
    value = JSON.stringify(value);
    if (encoded) {
      value = btoa(value);
    }
    localStorage.setItem(key, value);
  }
  // init(): void {
  //   this.apiToken = null;
  //   this.authentication = null;
  //   // const data = this.getLocalItem('gtmsauth', true);
  //   if (data) {
  //     // this.authentication = data['data'];
  //     this.authentication = data;
  //     this.apiToken = this.authentication['token'];
  //     console.log(data);

  //   }
  // }
  getAuthorization(): any {
    const authorization = {};
    if (this.authentication) {
      authorization['authorization'] = this.apiToken;
    }
    return authorization;
  }
  getValidCookieTime(resToken) {
    let dateTime = new Date(resToken["exp"] * 1000);
    let updatedDateTime = moment(dateTime, "H:mm:ss:SS")
      .add(5, "hours")
      .add(30, "minutes")
      .toDate();
    return updatedDateTime;
  }
  logout(): void {
    this.removeLocalStorage('zerv');
    this.removeLocalStorage('portalauthentication');
    this.removeLocalStorage('authentication');
    this.removeLocalStorage('token');
    this.init();
    this.onLogOut.emit();
  }
}
