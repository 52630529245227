import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonServices } from "../../services/commonservice";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
// import { NgxSpinnerService } from "ngx-spinner"; 

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
  providers: [CommonServices]
})
export class DashboardComponent implements OnInit {
  onSubmitLoading: boolean;
  dashboard: any = {};
  auditInfo: any = [];
  noData: any;
  constructor(
    private router: Router,
    private CommonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }
  ngOnInit() {
    this.spinnerService.show(); //show the spinner
    this.getDashboardCount();
    this.getClientAuditinfo();
  }
  getDashboardCount() {
    let token = localStorage.getItem("zerv");
    this.CommonServices.getDashboardCount(token).subscribe(
      res => {
        if (res.code == 200) {
          this.dashboard = res;
          this.spinnerService.hide();
          // console.log(this.dashboard);
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
        this.onSubmitLoading = false;
      }
    );
  }
  getClientAuditinfo() {
    this.spinnerService.show(); //show the spinner
    let token = localStorage.getItem("zerv");
    this.CommonServices.getClientAuditinfo(token).subscribe(
      res => {
        if (res.code == 200) {
          this.auditInfo = res.listUserAudit;
          if (this.auditInfo.length == 0) {
            this.noData = "No Data Found";
          }
          this.spinnerService.hide();
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        // console.log(err);
        this.onSubmitLoading = false;
        this.spinnerService.hide();
      }
    );
  }
}
