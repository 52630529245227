import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonServices } from "../../services/commonservice";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
@Component({
  selector: "app-audit-logs-client",
  templateUrl: "./audit-logs-client.component.html",
  styleUrls: ["./audit-logs-client.component.css"],
  providers: [CommonServices]
})
export class AuditLogsClientComponent implements OnInit {
  onSubmitLoading: boolean;
  auditInfo: any = [];
  text: any;
  noData: any;
  constructor(
    private router: Router,
    private CommonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }
  key: string = "name"; //set default
  reverse: boolean = false;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  p: number = 1;

  ngOnInit() {
    this.spinnerService.show();
    this.getAllClientAuditinfo();
  }
  getAllClientAuditinfo() {
    let token = localStorage.getItem("zerv");
    this.CommonServices.getAllClientAuditinfo(token).subscribe(
      res => {
        if (res.code == 200) {
          this.auditInfo = res.listUserAudit;
          if (this.auditInfo == 0) {
            this.noData = "No Data Found!"
          }
          this.spinnerService.hide();
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        // console.log(err);
        this.onSubmitLoading = false;
        this.spinnerService.hide();
        this.text = "No Data Found";
      }
    );
  }
}
