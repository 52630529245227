import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';
@Injectable()

export class AuthGuard implements CanActivate {
  helper = new JwtHelperService();
  decodedToken: any;
  token: any;
  role: any;
  zervPaths: Array<string> = ['dashboardZervAdmin', 'customers', 'usersZervAdmin', 'devicesZervAdmin', 'zervAuditLogs'];
  clientPaths: Array<string> = ['dashboard', 'users', 'devices', 'clientAuditLogs', 'clientReports'];
  constructor(private authService: AuthService, private router: Router, private cookieService: CookieService) {
    this.token = localStorage.getItem('zerv');

    // const auth = this.authService.authentication;
    // this.role = auth.userRole;
  }
  // private authService: AuthService, 
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // let url: string = state.url;
    // debugger
    // console.log(url);
    // this.decodeToken(this.token);
    // const path = next.routeConfig.path;
    // this.role = this.authService.authentication.userRole;
    // // this.role = localStorage.getItem("Role")
    // const currentUser = this.role;
    // const token = this.cookieService.get('token');
    const path = next.routeConfig.path;
    const token = localStorage.getItem('zerv');
    let currentuser = JSON.parse(atob(token.split(".")[1]));
    let authentication = currentuser;
    this.role = authentication['custom:role']

    if (this.role) {
      if (this.role == 'Zerv-Admin') {
        if (path == this.zervPaths[0] || path == this.zervPaths[1] || path == this.zervPaths[2] || path == this.zervPaths[3] || path == this.zervPaths[4]) {
          // console.log(path);
          return true;
        } else {
          return false;
        }
      }
      else if (this.role == 'Client-Admin') {
        if (path == this.clientPaths[0] || path == this.clientPaths[1] || path == this.clientPaths[2] || path == this.clientPaths[3] || path == this.clientPaths[4]) {
          // console.log(path);
          return true;
        } else {
          return false;
        }
      }
      return true;
    }
    // not logged in so redirect to login page with the return url

    this.authService.logout();
    // this.router.navigate(['/login']);
    // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    // location.reload();
    // return false;
  }
  // decodeToken(tkn: string): void {
  //   debugger
  //   if (tkn) {
  //     this.token = tkn;
  //     this.decodedToken = this.helper.decodeToken(tkn);
  //     this.role = this.decodedToken['custom:role'];
  //   }
  // }
}
