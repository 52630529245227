import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { CommonServices } from "../../services/commonservice";
@Component({
  selector: "app-users-zerv-admin",
  templateUrl: "./users-zerv-admin.component.html",
  styleUrls: ["./users-zerv-admin.component.css"],
  providers: [CommonServices]
})
export class UsersZervAdminComponent implements OnInit {
  userData: any = [];
  // user: any = {};
  allUserById: any = {};
  locationList: any;
  allUsers: any = {};
  onSubmitLoading: boolean;
  NoUserData: any;
  NoAllusersData: any;
  NoLocationListData: any;
  constructor(
    // private fb: FormBuilder,
    private router: Router,
    private CommonServices: CommonServices, // private imageploader: ImageUploaderComponent
    private spinnerService: Ng4LoadingSpinnerService
  ) {
  }
  //sorting
  key: string = "name"; //set default
  reverse: boolean = false;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing p to one
  p: number = 1;

  ngOnInit() {
    this.spinnerService.show();
    this.getMyUser();
    this.getAllUsers();
    // this.getLocation();
  }
  // get f() {
  //   return this.form.controls;
  // }
  getMyUser() {
    let token = localStorage.getItem("zerv");
    this.CommonServices.getUser(token).subscribe(
      res => {
        if (res.code == 200) {
          this.userData = res.listUsers;
          if (this.userData.length == 0) {
            this.NoUserData = "No Data Found!"
          }
          this.spinnerService.hide();
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
        this.onSubmitLoading = false;
      }
    );
  }
  getAllUsers() {
    let token = localStorage.getItem("zerv");
    this.CommonServices.getAllUsers(token).subscribe(
      res => {
        if (res.code == 200) {
          this.allUsers = res.listGetAllUsers;
          if (this.allUsers.length == 0) {
            this.NoAllusersData = "No Data Found!"
          }
          this.spinnerService.hide();
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
        this.onSubmitLoading = false;
      }
    );
  }
  getAllUserById(Id, customerId) {
    this.locationList = "";
    this.spinnerService.show();
    let custId = customerId;
    let token = localStorage.getItem("zerv");
    this.CommonServices.getUserById(token, Id, custId).subscribe(
      res => {
        if (res.code == 200) {
          this.allUserById = res;
          this.locationList = res.listGetUserAccess;
          if (this.locationList.length == 0) {
            this.NoLocationListData = "No Data Found!"
          }
          this.spinnerService.hide();
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
          this.spinnerService.hide();
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
        this.onSubmitLoading = false;
      }
    );
  }
  closeDetailModal() {
    this.allUserById = "";
    this.locationList = "";
  }
  // myUsers
  // getUserById(Id) {

  //   this.spinnerService.show();
  //   let token = localStorage.getItem("token");
  //   this.CommonServices.getUserById(token, Id).subscribe(
  //     res => {

  //       if (res.code == 200) {
  //         this.user = res;
  //         this.locationList = res.listGetUserAccess;
  //         this.spinnerService.hide();
  //         this.onSubmitLoading = false;
  //       } else {
  //         this.onSubmitLoading = false;
  //       }
  //     },
  //     err => {
  //       console.log(err);
  //       this.spinnerService.hide();
  //       this.onSubmitLoading = false;
  //     }
  //   );
  // }
}
