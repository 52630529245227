import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonServices } from "../../services/commonservice";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
@Component({
  selector: "app-audit-logs",
  templateUrl: "./audit-logs.component.html",
  styleUrls: ["./audit-logs.component.css"],
  providers: [CommonServices]
})
export class AuditLogsComponent implements OnInit {
  auditInfo: any = [];
  onSubmitLoading: boolean;
  message: any;
  noData: any;
  constructor(
    private router: Router,
    private CommonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }
  key: string = "time"; //set default
  reverse: boolean = false;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  p: number = 1;
  ngOnInit() {
    // this.spinnerService.show();
    this.getAllCustAuditinfo();
    // setTimeout(() => this.spinnerService.hide(), 1200000)
  }

  getAllCustAuditinfo() {

    this.spinnerService.show();
    // setTimeout(() => this.spinnerService.hide(), 60000)
    let token = localStorage.getItem("zerv");
    this.CommonServices.getAllCustAuditinfo(token).subscribe(
      res => {

        // this.spinnerService.show();
        if (res.code == 200) {

          // this.spinnerService.show();
          this.auditInfo = res.listUserAudit;
          if (this.auditInfo.length == 0) {
            this.noData = "No Data Found"
          }
          setTimeout(() => this.spinnerService.hide(), 20000)
          this.spinnerService.hide();
        } else {
          this.onSubmitLoading = false;
          this.spinnerService.hide();
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
        this.message = "No Data Found!";
        this.onSubmitLoading = false;
      }
    );
  }
}
