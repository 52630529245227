import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { Observable } from "rxjs";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { environment } from "../../environments/environment";
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class CommonServices {
  constructor(private http: Http, private toastr: ToastrService) { }
  private urlLogin = environment.API_URL + "v1/portal/login";
  private urlGetUsers = environment.API_URL + "/v1/portal/getusers";
  private urlGetUsersbyId = environment.API_URL + "/v1/portal/user/getuserwithtimezone";
  private urlpostUser = environment.API_URL + "/v1/portal/user/adduserwithtimezone";
  private urlCustomer = environment.API_URL + "/v1/portal/customer";
  private urlLocation = environment.API_URL + "/v1/portal/location";
  private urlDevice = environment.API_URL + "/v1/portal/device";
  private urlAssociate = environment.API_URL + "/v1/portal/device/associate";
  private urlClientDevices = environment.API_URL + "/v1/portal/clientdevice";
  private urlAssignDevice = environment.API_URL + "/v1/portal/assigndevice";
  private urlForgotPassword = environment.API_URL + "/v1/forgotpassword";
  private urlConfirmForgotPwd =
    environment.API_URL + "/v1/confirmforgotpassword";
  private urlGetAllUsers = environment.API_URL + "/v1/portal/getallusers";
  private urlGetDashboardCounts =
    environment.API_URL + "/v1/portal/dashboard/getoverview";
  private urlGetZervAuditInfo =
    environment.API_URL + "/v1/portal/zervadmin/useraudit";
  private urlGetClientAuditInfo = environment.API_URL + "/v1/portal/useraudit";
  private urlGetAllZervAuditInfo =
    environment.API_URL + "/v1/portal/zervadmin/useraudit/all";
  private urlGetAllClientAuditInfo =
    environment.API_URL + "/v1/portal/useraudit/all";
  private urlGetDeleteAddress = environment.API_URL + "/v1/portal/useraccess";
  private urlLocationAccess =
    environment.API_URL + "/v1/portal/active-or-inactive/useraccess";
  private urluserActive_Inactive =
    environment.API_URL + "v1/portal/active-or-inactive/user";
  private urlpostSwitchDevice =
    environment.API_URL + "/v1/portal/active-or-inactive/device";
  // private urlupdateUser = environment.API_URL + "v1/portal/user/{Id}";
  private urlupdateDevice = environment.API_URL + "/v1/portal/updatedevice";
  private updateuserandtimezone = environment.API_URL + "/v1/portal/user/updateuserandtimezone/{Id}";
  private urluserAccessDuration = environment.API_URL + "/v1/portal/user/useraccessduration";
  private urlsafeTrustDeviceSync = environment.API_URL + "/v1/safetrustdevicessync";
  private urlDeleteUser = environment.API_URL + "/v1/portal/user/deleteuser";
  private urlUpdateCustomer = environment.API_URL + "/v1/updatecustomer";
  private urlGetMultipleSubLoc = environment.API_URL + "/v1/portal/getsublocations";
  private urlGetDeviceUsersById = environment.API_URL + "/v1/getdeviceuser"
  private urlCustomerAdmins = environment.API_URL + "/v1/getcustomeradmins";


  showSuccess(message, title) {
    this.toastr.success(message, title)
  }

  showError(message, title) {
    this.toastr.error(message, title)
  }
  copyObject(object) {
    return JSON.parse(JSON.stringify(object));
  }

  login(obj) {

    let headers = new Headers({ "Content-Type": "application/json" }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .post(`${this.urlLogin}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getUser(key) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlGetUsers}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getDashboardCount(key) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlGetDashboardCounts}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getCustAuditinfo(key) {

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlGetZervAuditInfo}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getClientAuditinfo(key) {

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlGetClientAuditInfo}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getAllCustAuditinfo(key) {

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlGetAllZervAuditInfo}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getAllClientAuditinfo(key) {

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlGetAllClientAuditInfo}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  postUser(key, data) {

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .post(`${this.urlpostUser}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getUserById(key, Id, custId) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .get(`${this.urlGetUsersbyId}/${Id}?customerId=${custId}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getCustomer(key) {

    let headers = new Headers({ "Content-Type": "application/json", Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlCustomer}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getCustomerById(key, Id) {

    let headers = new Headers({ "Content-Type": "application/json", Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlCustomer}/${Id}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  postCustomer(key, obj) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .post(`${this.urlCustomer}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getLocation(key) {

    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlLocation}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getSubLocation(key, name) {

    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlLocation}/${name}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getDevices(key) {

    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlDevice}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  postDevice(key, obj) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .post(`${this.urlDevice}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  associate(key, obj) {

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .post(`${this.urlAssociate}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getClientDevices(key) {

    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlClientDevices}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  assignDevice(key, data) {

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .post(`${this.urlAssignDevice}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  forgotPwd(obj) {

    let headers = new Headers({ "Content-Type": "application/json" }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .post(`${this.urlForgotPassword}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  confirmForgotPwd(obj) {

    let headers = new Headers({ "Content-Type": "application/json" }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .post(`${this.urlConfirmForgotPwd}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getDeviceById(key, Id) {

    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .get(`${this.urlDevice}/${Id}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getAllUsers(key) {

    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlGetAllUsers}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getDeleteAddress(key, Id) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .delete(`${this.urlGetDeleteAddress}/${Id}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  locationAccess(key, obj) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .post(`${this.urlLocationAccess}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  userActive_Inactive(key, obj) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .post(`${this.urluserActive_Inactive}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  postSwitchDevice(key, obj) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .post(`${this.urlpostSwitchDevice}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  updateUser(key, data) {

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    let url = this.updateuserandtimezone.replace('{Id}', data.id);
    // {Id}
    return this.http
      .put(`${url}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  updateDevice(key, data, Id) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .put(`${this.urlupdateDevice}/${Id}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  userAccessDuration(key, obj) {

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .post(`${this.urluserAccessDuration}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getSafeTrustDeviceSync(key) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .get(`${this.urlsafeTrustDeviceSync}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  DeleteUser(key, Id) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option

    return this.http
      .delete(`${this.urlDeleteUser}/${Id}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  updateCustomerInfo(key, data, Id) {
    let headers = new Headers({ Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .put(`${this.urlUpdateCustomer}/${Id}`, data, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  multipleSubLocations(key, obj) {
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: key
    }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .post(`${this.urlGetMultipleSubLoc}`, obj, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getDeviceUsersById(key, Id) {
    let headers = new Headers({ "Content-Type": "application/json", Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .get(`${this.urlGetDeviceUsersById}/${Id}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
  getAdminListById(key, Id) {
    let headers = new Headers({ "Content-Type": "application/json", Authorization: key }); // ... Set content type to JSON
    let options = new RequestOptions({ headers: headers }); // Create a request option
    return this.http
      .get(`${this.urlCustomerAdmins}/${Id}`, options) // ...using post request
      .map((res: Response) => res.json()) // ...and calling .json() on the response to return data
      .catch((error: any) =>
        Observable.throw(error.json().error || "Server Error")
      );
  }
 
}

