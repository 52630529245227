// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_URL: "https://rqojzjdavg.execute-api.us-east-1.amazonaws.com/dev/"
  // API_URL: "https://nvdqtv8fgk.execute-api.us-east-1.amazonaws.com/Development/",
  // baseUrl: "https://nvdqtv8fgk.execute-api.us-east-1.amazonaws.com/Development",
  // baseUrl: "https://mml97aq5g5.execute-api.us-east-1.amazonaws.com/integration",
  // API_URL: "https://mml97aq5g5.execute-api.us-east-1.amazonaws.com/integration/"
  // baseUrl: "https://rn9v2wddff.execute-api.us-east-1.amazonaws.com/Stage",
  // API_URL: "https://rn9v2wddff.execute-api.us-east-1.amazonaws.com/Stage/"
  // baseUrl: "https://8628b29xxa.execute-api.us-east-1.amazonaws.com/Production",
  // API_URL: "https://8628b29xxa.execute-api.us-east-1.amazonaws.com/Production/"
  API_URL: "https://api.zervinc.net"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
