import { Component, OnInit } from "@angular/core";
import { BsDropdownConfig } from "ngx-bootstrap/dropdown";
import { Router, ActivatedRoute } from "@angular/router";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl
} from "@angular/forms";
import { CommonServices } from "../../services/commonservice";
@Component({
  selector: "app-devices",
  templateUrl: "./devices.component.html",
  styleUrls: ["./devices.component.css"],
  providers: [
    CommonServices,
    {
      provide: BsDropdownConfig,
      useValue: { isAnimated: true, autoClose: true }
    }
  ]
})
export class DevicesComponent implements OnInit {
  devices: any;
  form: FormGroup;
  onSubmitLoading: boolean;
  submitted = false;
  assignDeviceData: any = {};
  deviceMACId: any;
  locations: any;
  sLocation: any;
  universalAccessCode: any;
  locationName: any;
  subLocationName: any;
  lFName: any;
  sLFName: any;
  cardType: any;
  loc: any;
  noData: any;
  deviceUsersDetails: any;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private CommonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      location: ["", Validators.required],
      subLocation: ["", Validators.required],
      locationFriendly: ["", Validators.required],
      subLocationFriendly: ["", Validators.required],
      cardType: ["", Validators.required],
      universalCode: [""]
    });
    this.spinnerService.show();
    this.getDevices();
    this.getLocation();
  }
  getLocation() {
    let token = localStorage.getItem("zerv");
    this.CommonServices.getLocation(token).subscribe(
      res => {

        if (res.code == 200) {
          this.locations = res.listLocations;
          this.spinnerService.hide();
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
        this.onSubmitLoading = false;
      }
    );
  }
  getSubLocation(e) {
    let token = localStorage.getItem("zerv");
    this.CommonServices.getSubLocation(token, e).subscribe(
      res => {
        if (res.code == 200) {
          this.sLocation = res.listSublocations;
          this.spinnerService.hide();
          // console.log(this.sLocation);
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        // console.log(err);
        this.onSubmitLoading = false;
        this.spinnerService.hide();
      }
    );
  }
  getDevices() {
    let token = localStorage.getItem("zerv");
    this.CommonServices.getClientDevices(token).subscribe(
      res => {
        if (res.code == 200) {
          this.devices = res.listGetDevices;
          if (this.devices.length == 0) {
            this.noData = "No Data Found!"
          }
          this.spinnerService.hide();
          this.onSubmitLoading = false;
        } else {
          this.spinnerService.hide();
          this.onSubmitLoading = false;
        }
      },
      err => {
        // console.log(err);
        this.onSubmitLoading = false;
        this.spinnerService.hide();
      }
    );
  }
  getDeviceskey(value) {
    this.deviceMACId = value;
    this.devices.filter(item => {
      if (value == item.deviceMACId) {
        (this.lFName = item.locationFriendlyName),
          (this.subLocationName = item.subLocationName),
          (this.locationName = item.locationName),
          (this.sLFName = item.subLocationFriendlyName),
          (this.cardType = item.cardType),
          (this.universalAccessCode = item.universalAccessCode);

        this.assignDeviceData["location"] = item.locationName;
        this.assignDeviceData["subLocation"] = item.subLocationName;
        this.assignDeviceData["locationFriendly"] = item.locationFriendlyName;
        this.assignDeviceData["subLocationFriendly"] =
          item.subLocationFriendlyName;
        this.assignDeviceData["cardType"] = item.cardType;
        this.assignDeviceData["universalCode"] = item.universalAccessCode;
      }
    });
    this.spinnerService.hide();
  }
  assignDevice() {
    this.submitted = true;
    let formData = this.assignDeviceData;
    let obj = {
      deviceMACId: this.deviceMACId,
      installLocation: formData.location,
      installSubLocation: formData.subLocation,
      installLocationFriendlyName: formData.locationFriendly,
      installSubLocationFriendlyName: formData.subLocationFriendly,
      cardType: formData.cardType,
      universalAccessCode: formData.universalCode
    };
    // console.log(obj);
    let token = localStorage.getItem("zerv");
    this.CommonServices.assignDevice(token, obj).subscribe(
      res => {
        if (res.code == 200) {
          this.form.reset();
          this.getDevices();
          this.onSubmitLoading = false;
        } else {
          this.form.reset();
          this.onSubmitLoading = false;
        }
      },
      err => {
        this.onSubmitLoading = false;
      }
    );
  }
  getDeviceUsersById(Id,Modal) {
    this.spinnerService.show();
    let token = localStorage.getItem("zerv");
    this.CommonServices.getDeviceUsersById(token, Id).subscribe(
      res => {
        if (res.code == 200) {
          this.deviceUsersDetails = res.data;
          this.spinnerService.hide();
          Modal.show();
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
          Modal.show();
        }
      },
      err => {
        // console.log(err);
        this.onSubmitLoading = false;
        this.spinnerService.hide();
      }
    );
  }
}
